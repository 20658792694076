import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { portal } from './Portal.module.scss';
import { Link } from 'gatsby';

const Secured1 = () => {
  const { isAuthenticated, loading, logout, user, loginWithPopup } = useAuth0()
  if (loading) {
    return <p>Loading...</p>
  }

  return (
    <div>
        <p><h3-w>Welcome to Blockworks Software Portal</h3-w></p>
        <br/>
      {isAuthenticated ? (
        <>
          <button onClick={() => logout()}>Log out</button>
          <p><pre-w>{isAuthenticated && JSON.stringify(user.given_name, null, 2).replace(/\"/g, "")}, you are not an 
          authorized user. <Link to="/">Return Home</Link></pre-w></p>
        </>
      ) : (
        <>
          <p><p-w>To access the portal, you need a Blockworks Software user account.<br/>
            Please click here to login <button onClick={() => loginWithPopup()}>Log in</button>
          </p-w></p>
        </>
      )}
      <p><p-w><small>This site is for authorized use only. For your protection, all activity is logged.</small></p-w></p>
    </div>
  )
}

export default Secured1;